import Vue from 'vue'
import VueRouter from 'vue-router'
import frameWorkRouter from '../framework/router/index.js'

Vue.use(VueRouter)

let indexSubRoutes = [
    {
        path: 'index',
        name: 'Index',
        component: () => import('../views/Index.vue'),
        children: indexSubRoutes
    }
];

indexSubRoutes = indexSubRoutes.concat(frameWorkRouter);
const routes = [
    {
        path: '/',
        name: 'Index',
        component: () => import('../framework/Index.vue'),
        children: indexSubRoutes
    },
	{
		path: '/merchantOrder',
		name: 'merchantOrder',
		component: () => import('@/views/merchant.vue'),
		children: [{
			path: "/merchantInfo",
			name: 'merchantInfo',
			title: '商户管理',
			component: () => import('@/views/merchantInfo/merchantInfo.vue'),
		},{
			path: "/payConfig",
			name: 'payConfig',
			title: '支付配置',
			component: () => import('@/views/payConfig/payConfig.vue'),
		},{
			path: "/merchantOrder",
			name: 'merchantOrder',
			title: '订单管理',
			component: () => import('@/views/merchantOrder/merchantOrder.vue'),
		},{
			path: "/userOrder",
			name: 'userOrder',
			title: '用户余额',
			component: () => import('@/views/userOrder/userOrder.vue'),
		}
		]
	},
    {
        path: '/login',
        name: 'Login',
        component: () => import('../framework/Login.vue')
    },
    {
        path: '/about',
        name: 'About',
        component: () => import('../framework/About.vue')
    }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

router.afterEach(to => {
    console.log(to)
    // 返回页面顶端
    window.scrollTo(0, 0);
});

export default router
