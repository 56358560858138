const FrameworkRouter = [
    {
        path: "role",
        name: 'Role',
        component: () => import('../role/Role.vue'),
    },
    {
        path: "menu",
        name: 'Menu',
        component: () => import('../menu/Menu.vue'),
    },
    {
        path: "user",
        name: 'User',
        component: () => import('../user/User.vue'),
    },
    {
        path: "medifyUserPassword",
        name: 'MedifyUserPassword',
        title: '修改密码',
        component: () => import('../user/MedifyUserPassword.vue'),
    },
    {
        path: "job/config",
        name: 'jobConfig',
        title: '任务配置',
        component: () => import('../job/config'),
    },
    {
        path: "org",
        name: 'org',
        title: '组织机构',
        component: () => import('../org/Org'),
    },
    {
        path: "refresh",
        name: 'refresh',
        title: '刷新页面',
        component: () => import('../Refresh.vue'),
    }

];
export default FrameworkRouter
