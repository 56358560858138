import {
	post,
	// get
} from '/src/lib/request.js'


/**
 * 获取商户信息
 * @param params
 */

export const weChatMerchantConfigGet = (params) => {
	return post(`/merchant/weChatMerchantConfig/get`, params)
};
/**
 * 获取商户支付配置
 * @param params
 */
export const weChatMerchantPaymentConfigList = (params) => {
	return post(`/merchant/weChatMerchantPaymentConfig/list`, params)
};
/**
 * 删除商户支付配置
 * @param params
 */
export const weChatMerchantPaymentConfigDel = (params) => {
	return post(`/merchant/weChatMerchantPaymentConfig/del`, params)
};

/**
 * 保存商户支付配置
 * @param params
 */
export const weChatMerchantPaymentConfigSave = (params) => {
	return post(`/merchant/weChatMerchantPaymentConfig/save`, params)
};

/**
 * 更新商户支付配置
 * @param params
 */
export const weChatMerchantPaymentConfigUpdate = (params) => {
	return post(`/merchant/weChatMerchantPaymentConfig/update`, params)
};
/**
 * 订单分页查询
 * @param params
 */
export const orderPage = (params) => {
	return post(`/merchant/order/page`, params)
};
/**
 * 微信用户分页查询
 * @param params
 */
export const weChatUserAmountPage = (params) => {
	return post(`/merchant/weChatUserAmount/page`, params)
};
/**
 * 扣款
 * @param params
 */
export const weChatUserAmountDeductMoney = (params) => {
	return post(`/merchant/weChatUserAmount/deductMoney`, params)
};

