import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import user from "./module/user"
import menu from "./module/menu"
import org from "./module/org"

const store = new Vuex.Store({
    modules:{
        user,
        menu,
        org
    }
})

export default store
