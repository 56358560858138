/**
  * axios封装
  * 请求拦截、响应拦截、错误统一处理
  */
import axios from 'axios';
import router from '../router';
import store from '../store'
import { Message,Spin } from 'view-design'
import jsCookie from 'js-cookie'

/**
  * 跳转登录页
  * 携带当前页面路由，以期在登录页面完成登录后返回当前页面
  */
const toLogin = () => {
    router.push("/login")
}

/**
  * 请求失败后的错误统一处理
  * @param {Number} status 请求失败的状态码
  */
const errorHandle = (status, other) => {
    // 状态码判断
    switch (status) {
        case 401: // 401: 未登录状态，跳转登录页
            toLogin();
            break;
        case 403: // 403 token过期 清除token并跳转登录页
            Message.warning('登录过期，请重新登录');
            localStorage.removeItem('token');
            setTimeout(() => {
                toLogin();
            }, 1000);
            break;
        case 404:
            Message.error('请求的资源不存在');
            break;
        case 500:
            Message.error(other);
            break;
        case 400:
            Message.error(other);
            break;
        default:
            Message.error(other);
            router.push("/login")
        }
    }

// 创建axios实例
var instance = axios.create({
    timeout: 1000 * 12,
});
// 设置post请求头
instance.defaults.headers.post['Content-Type'] = 'application/json';
/**
  * 请求拦截器
  * 每次请求前，如果存在token则在请求头中携带token
  */
instance.interceptors.request.use(
    config => {
        // 登录流程控制中，根据本地是否存在token判断用户的登录情况
        // 但是即使token存在，也有可能token是过期的，所以在每次的请求头中携带token
        // 后台根据携带的token判断用户的登录情况，并返回给我们对应的状态码
        // 而后我们可以在响应拦截器中，根据状态码进行一些统一的操作。
        let token = jsCookie.get("token")
        token && (config.headers.Authorization = token);

        if(config.org === undefined){ //默认机构ID
            config.org = true
        }
        if(config.org && store.state.org.currentOrg.uuid){
            if(!config.data){
                config['data'] = {}
                config.data = {
                    organizationUuid: store.state.org.currentOrg.uuid
                }
            } else if(Array.isArray(config.data)){
                config.data.forEach(v => {
                    v['organizationUuid'] = store.state.org.currentOrg.uuid
                })
            }else if(config.data.constructor == Object){
                config.data['organizationUuid'] = store.state.org.currentOrg.uuid
            }
        }
        console.log(config)
		Spin.show({
			render: (h) => {
				return h('div', [
					h('Icon', {
						
						props: {
							type: 'ios-loading',
							size: 18
						}
					}),
					h('div', '数据加载中……')
				])
			}
		});
        return config;
    },
    error => Promise.error(error)
);

// 响应拦截器
instance.interceptors.response.use(
    // 请求成功
    res => {
		Spin.hide()
        if(res.status === 200){
            if(res.data.code != 200){
                console.error(res);
                errorHandle(res.data.code, res.data.msg);
                return Promise.reject(res.data);
            } 
            return Promise.resolve(res)
        }else {
            return Promise.reject(res)
        }
    },
    // 请求失败
    error => {
		Spin.hide()
        const { response } = error;
        console.error("error:", response)
        if (response) {
            // 请求已发出，但是不在2xx的范围
            errorHandle(response.status, response.data.message);
            return Promise.reject(response.data);
        }
    }
);
const request = (url, data, config) => {
    return instance(process.env.VUE_APP_BASE_URL + url, data, config)
}

const post = (url, data, config) => {
    if(!config){
        config = {}
    }
    config["method"] = "post";
    return instance.post(process.env.VUE_APP_BASE_URL + url, data, config)
}

const get = (url, data, config) => {
    if(!config){
        config = {}
    }
    config["method"] = "get";
    return instance.get(process.env.VUE_APP_BASE_URL + url, config)
}
export {
    request,
    post,
    get
}
    