import {
	Message
} from 'view-design';
import {
	get
} from '/src/lib/request.js'
import {
	weChatMerchantConfigGet
} from '/src/api/merchant/merchant.js'
export default {
	namespaced: true, //命名空间
	state: {
		orgs: [],
		orgTree: [],
		currentOrg: {},
	},
	getters: {
		getOrgTree(state) {
			return state.orgTree;
		},
		getOrgs(state) {
			return state.orgs;
		},
		getCurrentOrg(state) {
			return state.currentOrg;
		}
	},
	mutations: {
		save(state, data) {
			state.orgs = data.data;

			let orgParentUuidOrgMap = {}
			let orgUuidMap = {}
			state.orgs.forEach(org => {
				orgUuidMap[org.uuid] = org.uuid
				if (!org.parentUuid) {
					orgParentUuidOrgMap[''] = [org]
				} else {
					if (!orgParentUuidOrgMap[org.parentUuid]) {
						orgParentUuidOrgMap[org.parentUuid] = []
					}
					orgParentUuidOrgMap[org.parentUuid].push(org)
				}
			})
			let loopOrg = (org) => {
				let arr = orgParentUuidOrgMap[org.uuid];
				if (arr) {
					org['children'] = arr
					org['expand'] = true
					arr.forEach(childOrg => {
						childOrg['title'] = childOrg.name;
						childOrg['label'] = childOrg.name;
						childOrg['id'] = childOrg.uuid;
						loopOrg(childOrg)
					})
				}
			}
			let rootList = state.orgs.filter(org => {
				return !orgUuidMap[org.parentUuid];
			})
			rootList.forEach(rootOrg => {
				rootOrg['title'] = rootOrg.name;
				rootOrg['label'] = rootOrg.name;
				rootOrg['id'] = rootOrg.uuid;
				loopOrg(rootOrg, state.orgs)
			})

			state.currentOrg = rootList[0]
			state.orgTree = rootList;
			weChatMerchantConfigGet().then(res => {
				sessionStorage.setItem("merchantInfo", JSON.stringify(res.data.data))
			}).catch((err) => {
				console.log(err)
				Message.error("商户加载失败")
			})
		},
		clear(state) {
			state.orgs = [];
			state.orgTree = [];
			state.currentOrg = {};
		},
		selectedOrg(state, uuid) {
			let orgs = state.orgs.filter(value => value.uuid === uuid);
			state.currentOrg = orgs[0];
		}
	},
	actions: {
		load({
			commit
		}) {
			get("/user/orgs").then((response) => {
				commit("save", {
					data: response.data.data
				})
			}).catch((e) => {
				console.log(e)
				Message.error("组织机构加载失败")
			})

		},
		clear({
			commit
		}) {
			commit("clear")
		},
		selectedOrg({
			commit
		}, uuid) {
			commit("selectedOrg", uuid)
		}
	}
}