import router from '/src/router'
import { Message } from 'view-design';
import { get } from '/src/lib/request.js'

export default {
    namespaced: true,//命名空间
    state: {
        allMenu:[],
        headerMenu:[],
        currentSideMenu:[],
        selectedHeaderMenuUuid: '',
        selectedSideMenuUuid: ''
    },
    getters: {
        getHeaderMenu(state){
            return state.headerMenu;
        },
        getCurrentSideMenu(state){
            return state.currentSideMenu;
        }
    },
    mutations: {
        save(state, data){
            let menus = data.data
            if(menus){
                state.allMenu = menus;
                let menusTemp = [];
                let menusMap = {}
                menus.forEach((m) => {
                    if (!m.parentUuid){
                        menusTemp.push(m);
                        loop(m, menus)
                    }
                    menusMap[m.uuid] = m
                })
                menusTemp = menusTemp.sort((a, b) => {
                    return a.sortby - b.sortby
                })
                state.headerMenu = menusTemp;

                if(data.status){
                    let menu = state.allMenu.filter(value => value.url === router.history.current.fullPath)
                    if(menu.length > 0){
                        if(!menu[0].parentUuid){
                            this.commit("menu/setSelectedHeaderMenu", menu[0].uuid)
                        }else {
                            let headerMenuTemp = getSideMenuLoop(menu[0])
                            state.currentSideMenu = headerMenuTemp.children
                            this.commit("menu/setSelectedHeaderMenu", headerMenuTemp.uuid)
                            this.commit("menu/setCurrentSideMenu", menu[0].uuid)
                        }
                    }
                }else {
                    state.currentSideMenu = state.headerMenu[0].children
                    this.commit("menu/setSelectedHeaderMenu", state.headerMenu[0].uuid)
                    if(state.headerMenu[0].children && state.headerMenu[0].children.length > 0){
                        this.commit("menu/setCurrentSideMenu", state.headerMenu[0].children[0].uuid)
                        router.push(state.headerMenu[0].children[0].url)
                    }else {
                        router.push(state.headerMenu[0].url)
                    }
                }
            }

            function getSideMenuLoop(menu){
                let menuTemp =  state.allMenu.filter(value => value.uuid === menu.parentUuid)
                if(menuTemp.length > 0 && menuTemp[0].parentUuid){
                    getSideMenuLoop(menuTemp[0])
                }else {
                    return menuTemp[0]
                }
            }

            function loop (menu, menus) {
                menus.forEach((m) => {
                    if(m.parentUuid == menu.uuid){
                        if(!menu.children){
                            menu['children'] = []
                        }
                        menu.children.push(m)
                        loop(m, menus)
                    }
                })

                if(menu.children){
                    menu.children = menu.children.sort((a, b) => {
                        return a.sortby - b.sortby
                    })
                }
            }
        },
        clear(state){
            state.allMenu = [];
            state.headerMenu = [];
            state.currentSideMenu = [];
            state.selectedHeaderMenuUuid = ''
            state.selectedSideMenuUuid = ''
        },
        setSelectedHeaderMenu(state, headerMenuUuid){
            state.selectedHeaderMenuUuid = headerMenuUuid
        },
        setSelectedSideMenu(state, sideMenuUuid){
            state.selectedSideMenuUuid = sideMenuUuid
        },
        setCurrentSideMenu(state, headerMenuUuid){
            let sideMenu = state.headerMenu.filter(value => value.uuid === headerMenuUuid)
            state.currentSideMenu = sideMenu.length > 0 ? sideMenu[0].children ? sideMenu[0].children : [] : [];
        },
        selectSideMenuNoOne(state){
            if(state.currentSideMenu.length > 0){
                router.push(state.currentSideMenu[0].url)
                this.commit("menu/setSelectedSideMenu", state.currentSideMenu[0].uuid)
            }else {
                let headerMenu = state.headerMenu.filter(value => value.uuid == state.selectedHeaderMenuUuid)
                if( headerMenu.length > 0 ){
                    router.push(headerMenu[0].url)
                }else {
                    router.push("/index")
                }
            }
        }
    },
    actions: {
        load({ commit }, status) {
            get("/user/permission").then((response) => {
                commit("save", {data: response.data.data, status: status})
            }).catch((e)=>{
                console.log(e)
                Message.error("菜单加载失败")
            })
        },
        clear({ commit }) {
            commit("clear")
        },
        selectedHeader({ commit }, uuid){
            commit("setSelectedHeaderMenu", uuid)
            commit("setCurrentSideMenu", uuid)
            commit("selectSideMenuNoOne")
        },
        selectedSide({ commit }, uuid){
            commit("setSelectedSideMenu", uuid)
        }
    }
}
