import Cookies from 'js-cookie';
import config from '/src/config/index';
export default {
    namespaced: true,//命名空间
    state: {
        // info: {
        //     set(newVal) {
        //         console.log(newVal)
        //         sessionStorage.setItem("info", newVal)
        //     },
        //     get(){
        //         sessionStorage.getItem("info")
        //     }
        // },

        get info(){
            try {
                let data = localStorage.getItem("info");
                if(data){
                    data =JSON.parse(data)
                    let sessionid = Cookies.get('id', {path : config.cookieIdPaht});
                    if(data.id && data.id == sessionid){
                        return data;
                    }
                    localStorage.removeItem("info")
                    return {}
                }
                return {}
            }catch (e) {
                return {}
            }
        },
        set info(newVal) {
            let sessionid = Cookies.get('id', {path : config.cookieIdPaht});
            newVal['id'] = sessionid
            localStorage.setItem("info", JSON.stringify(newVal))
        }
    },
    getters: {
        getUserInfo(state){
            console.log(state.info)
            return state.info;
        }
    },
    mutations: {
        saveInfo(state, info){
            state.info = info
            this.dispatch('menu/load')
            this.dispatch("org/load")
        },
        clearInfo(state){
            state.info = {};
            this.dispatch('menu/clear')
            this.dispatch("org/clear")
        }
    },
    actions: {

    }
}
