import Vue from 'vue'
import App from './App.vue'
import router from './router'
Vue.prototype.router = router

import store from './store'
Vue.prototype.store = store;

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI)

import iView from 'view-design'
import 'view-design/dist/styles/iview.css';
Vue.use(iView)

import "./assets/css/global.css"

import {request, post, get} from './lib/request.js'
Vue.prototype.request = request
Vue.prototype.post = post
Vue.prototype.get = get
import dayjs from 'dayjs';
Vue.prototype.$dayjs = dayjs;
import config from './config/index.js'
Vue.prototype.config = config

Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App),
    created() {
        this.router.onReady(() => {
            if(!this.$store.getters["user/getUserInfo"].username){
                router.push("/login")
            }else {
                this.$store.dispatch("menu/load", true)
                this.$store.dispatch("org/load")
            }
        })
    }
}).$mount('#app')
